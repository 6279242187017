import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState: {
    organizations: loadDataInitialState({ rows: [], count: 0, page: 1 }),
    sms: loadDataInitialState({ rows: [], count: 0, page: 1 }),
    logs: loadDataInitialState({ rows: [], count: 0, page: 1 }),
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchOrganizations, 'organizations'),
    ...loadDataExtraReducer(thunks.fetchSms, 'sms'),
    ...loadDataExtraReducer(thunks.fetchLogs, 'logs'),
  },
});

export default superAdminSlice.reducer;
