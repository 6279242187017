import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "../../../components/Icon/Icon";
import { formatDate } from "utils/date";
import CertificateStatusLabel from "./CertificateStatusLabel";
import Grid from "@mui/material/Grid";
import FormikTextInput from "../../../form/FormikTextInput";

import {
  CATEGORY_CERTIFICATE,
  CATEGORY_SUBSCRIPTION,
  getCertificateIcon,
  getCertificateType,
  TYPE_VISITS
} from "utils/certificate";
import { capitalize } from "utils/utils";
import FormikDatepicker from "../../../form/FormikDatepicker";
import Button from "../../../components/Button/Button";
import { Form, withFormik } from "formik";
import store from "state/store";
import { certificateThunks } from "state/ducks/certificate";
import { billingThunks } from "state/ducks/billing";
import { useDispatch, useSelector } from "react-redux";
import { placeSelectors } from "state/ducks/place";
import { toastrActions } from "state/ducks/toastr";
import { useMediaQuery } from "@mui/material";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import useModal from "utils/hooks/useModal/useModal";
import FormikCheckbox from "../../../form/FormikCheckbox";
import FormikClientPhoneInput from "../../../form/FormikClientPhoneInput";
import * as Yup from "yup";
import phone from "phone";

const t = mova.ns('components.Certificates');

const CertificateInfoModal = ({ onClose, billing, submitForm, reloadCertificates }) => {
  const [deleteModalOpen, deleteCertificate, closeDeleteModal] = useModal();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const { certificate } = billing;
  const dispatch = useDispatch();

  const resendSms = async () => {
    await dispatch(billingThunks.resendSms({ type: 'certificateLink', placeId: activePlace.id, billingId: billing.id }));
    dispatch(toastrActions.showToastr({ message: t('messages.smsResent') }));
  };

  const confirmDeleteCertificate = async () => {
    await dispatch(certificateThunks.deleteCertificate({ placeId: activePlace.id, certificateId: billing.id }));
    onClose();
    reloadCertificates();
  }

  const smallView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Modal
      open
      onClose={onClose}
      actions={(
        <Box display='flex' justifyContent='space-between' flexWrap='wrap' sx={{ width: '100%' }} gap={2}>
          <Button
            onClick={deleteCertificate}
            fullWidth={smallView}
            variant='outlined'
            color='primary'
            startIcon={<Icon type='remove' />}
          >
            {t('delete')}
          </Button>
          <Box display='flex' justifyContent='flex-end' flexWrap='wrap' gap={2} flexGrow={1}>
            <Button fullWidth={smallView} variant='outlined' color='primary' size='large' onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button fullWidth={smallView} size='large' onClick={submitForm}>{t('save')}</Button>
          </Box>
        </Box>
      )}
      maxWidth='md'
    >
      <Form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.name')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography noWrap>{certificate.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.id')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{billing.data.uuid}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.purchased')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{formatDate(billing.createdAt)}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.clientName')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormikTextInput name='data.user.firstName' fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.clientLastName')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormikTextInput name='data.user.lastName' fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.phone')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormikClientPhoneInput
              name='data.user.phone'
              sx={{ '.MuiOutlinedInput-root': { borderRadius: '8px', height: 40, paddingTop: 0 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.category')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            {certificate.category === CATEGORY_CERTIFICATE && <Typography>{t('fields.categoryCertificate')}</Typography>}
            {certificate.category === CATEGORY_SUBSCRIPTION && <Typography>{t('fields.categorySubscription')}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.type')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box display='flex' alignItems='center' gap={2}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                width={36}
                height={36}
                sx={{ borderRadius: '50%', backgroundColor: 'primary.main' }}
              >
                <Icon type={getCertificateIcon(certificate)} color='white' />
              </Box>
              <Typography>{getCertificateType(certificate, t)}</Typography>
            </Box>
          </Grid>
          {certificate.data.type === TYPE_VISITS && (
            <>
              <Grid item xs={12} sm={4}>
                <Typography variant='h3'>{capitalize(t('visits'))}:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={4}>
                    <FormikTextInput name='data.alreadyVisited' fullWidth />
                  </Grid>
                  <Grid item xs={8}>
                    / {certificate.data.visits}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.expiration')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormikDatepicker name='data.expirationDate' fullWidth datepickerProps={{ withPortal: true }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.status')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box display='flex' alignItems='center' gap={2}>
              <CertificateStatusLabel billing={billing} />
              {billing.data?.status === 'INACTIVE' && <Button>{t('activate')}</Button>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('fields.comment')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormikTextInput multiline name='data.comment' fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3'>{t('sms')}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box display='flex' alignItems='center' gap={2}>
              <Typography>{t(billing.data?.smsMessageId ? 'smsSent' : 'smsNotSent')}</Typography>
              <Button color='primary' variant='outlined' onClick={resendSms}>{t('sendAgain')}</Button>
            </Box>
          </Grid>
          {['DRAFT', 'READY', 'INPROCESS'].includes(billing.data?.status) && (
            <Grid item xs={12}>
              <FormikCheckbox name='data.paid' label={t('paid')} />
            </Grid>
          )}
        </Grid>
      </Form>
      <ConfirmModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        confirm={confirmDeleteCertificate}
        title={t('deletingCertificate')}
        content={<Typography>{t('confirmDeleteCertificate')}</Typography>}
      />
    </Modal>
  );
};

CertificateInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const schema = Yup.object().shape({
  data: Yup.object().shape({
    user: Yup.object().shape({
      phone: Yup.string()
        .required(() => t('errors.required'))
        .test(
          'isValidPhoneLength',
          () => t('errors.phone'),
          (value) => {
            const result = phone(value);
            return result.isValid && value === result.phoneNumber;
          }),
    }),
  }),
});

export default memo(withFormik({
  mapPropsToValues: ({ billing }) => ({
    data: {
      user: {
        firstName: billing?.data?.user?.firstName || '',
        lastName: billing?.data?.user?.lastName || '',
        phone: billing?.data?.user?.phone || '',
      },
      alreadyVisited: billing?.data?.alreadyVisited || '',
      expirationDate: billing?.data?.expirationDate || '',
      comment: billing?.data?.comment || '',
      paid: billing?.data?.paid || false,
    },
  }),
  handleSubmit: async (data, helpers) => {
    await store.dispatch(certificateThunks.editCertificate({
      placeId: helpers.props.placeId,
      billingId: helpers.props.billing.id,
      data
    }))
    helpers.props.reloadCertificates();
    helpers.props.onClose();
  },
  validationSchema: schema,
})(CertificateInfoModal));
