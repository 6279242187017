import React, { memo, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material';
import mova from 'mova';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import TextInput from "../../form/TextInput";

const t = mova.ns('components.SearchInput');

const SearchInput = ({ small = false, onActivate = () => {}, onDeactivate = () => {}, sx = {}, paramName = 'q',
                       placeholder = t('search'), ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const currentPath = queryString.parse(location.search);
  const [value, setValue] = useState(currentPath?.[paramName] || '');
  const theme = useTheme();
  const inputRef = useRef();

  const onSearch = (val) => {
    setValue(val);
    const urlParams = queryString.parse(location.search);
    urlParams[paramName] = val;
    history.push(`${location.pathname}?${queryString.stringify(urlParams)}`);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch(value);
    }
  };

  const onClear = () => {
    onSearch('');
    onDeactivate();
  };

  const onBlur = () => {
    if (!value) {
      onDeactivate();
    } else {
      onSearch(value);
    }
  };

  const onSearchIconClick = () => {
    inputRef.current.focus();
    onActivate();
  };

  return (
    <TextInput
      sx={small ? { ...sx, width: theme.spacing(5) } : { minWidth: theme.spacing(15), ...sx }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <IconButton onClick={onSearchIconClick} edge='start'>
              <Icon type='search' />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: !!value && (
          <InputAdornment position='end'>
            <IconButton edge='end' onClick={onClear}>
              <Icon type='close' />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  small: PropTypes.bool,
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func
};

export default memo(SearchInput);
