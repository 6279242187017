import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const hallSlice = createSlice({
  name: 'dictionary',
  initialState: {
    countries: loadDataInitialState([]),
    cities: loadDataInitialState([]),
    cusines: loadDataInitialState([]),
    moods: loadDataInitialState([]),
    payments: loadDataInitialState([]),
    tags: loadDataInitialState([]),
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchCountries, 'countries'),
    ...loadDataExtraReducer(thunks.fetchCities, 'cities'),
    ...loadDataExtraReducer(thunks.fetchCusines, 'cusines'),
    ...loadDataExtraReducer(thunks.fetchMoods, 'moods'),
    ...loadDataExtraReducer(thunks.fetchPayments, 'payments'),
    ...loadDataExtraReducer(thunks.fetchTags, 'tags'),
  },
});

export default hallSlice.reducer;
