import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Loader from '@components/Loader/Loader';
import { placeSelectors } from 'state/ducks/place';
import { useSelector } from 'react-redux';
import PortmoneIntegrations from "./components/PortmoneIntegrations";
import UkrCardIntegrations from "./components/UkrCardIntegrations";
import PaymentProviderSelection from "./components/PaymentProviderSelection";

const Payments = () => {
  const isPlaceLoading = useSelector(placeSelectors.getPlaceLoading());

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {isPlaceLoading && <Loader fullScreen />}
      <PaymentProviderSelection />
      <PortmoneIntegrations />
      <UkrCardIntegrations />
    </Box>
  );
};

export default memo(Payments);
