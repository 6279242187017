import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';
import queryString from "query-string";

const fetchOrganizations = createAsyncThunk('superAdmin/fetchOrganizations', async () => {
  return api.get('/organisations');
});

const createOrganization = createAsyncThunk('superAdmin/createOrganization', async organization => {
  return api.post('/organisations', organization);
});

const updateOrganization = createAsyncThunk('superAdmin/updateOrganization', async organization => {
  return api.put(`/organisations/${organization.id}`, organization);
});

const deleteOrganization = createAsyncThunk('superAdmin/deleteOrganization', async organization => {
  return api.delete(`/organisations/${organization.id}`);
});

const fetchSms = createAsyncThunk('superAdmin/fetchSms', async (query) => {
  return api.get(`/logsSms?${queryString.stringify(query)}`);
});

const resendSms = createAsyncThunk('superAdmin/resendSms', async (smsId) => {
  return api.post(`/logsSms/${smsId}/resend`);
});

const fetchLogs = createAsyncThunk('superAdmin/fetchLogs', async (query) => {
  return api.get(`/logs/systemLogs?${queryString.stringify(query)}`);
});

export default {
  fetchOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  fetchSms,
  resendSms,
  fetchLogs,
};
