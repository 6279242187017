import React, { memo } from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormikTextInput from "../../../../../form/FormikTextInput";
import mova from 'mova';
import { useMediaQuery } from "@mui/material";
import Icon from "../../../../../components/Icon/Icon";

const t = mova.ns('components.Payments');

const PortmoneIntegrationRow = ({ place, name, type, placeholder }) => {
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const connected = !!place.connections?.[name];

  return (
    <Box
      display='flex'
      alignItems={mobileView ? 'flex-start' : 'center'}
      justifyContent='space-between'
      flexDirection={mobileView ? 'column' : 'row'}
    >
      <Box display='flex' alignItems='center' gap={1}>
        <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
          {t(`types.${type}`)}
        </Typography>
        {connected && <Icon type='done' sx={{ mr: 1 }} />}
      </Box>
      <FormikTextInput sx={{ flexGrow: 3 }} name={name} fullWidth placeholder={placeholder ||t('portmoneKey')} />
    </Box>
  );
};

export default memo(PortmoneIntegrationRow);
