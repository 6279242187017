import React from 'react';
import * as Yup from 'yup';
import mova from 'mova';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { toastrActions } from 'state/ducks/toastr';
import PortmoneIntegrationRow from "./PortmoneIntegrationRow";

const t = mova.ns('components.Payments');
const tSettings = mova.ns('components.Settings');

const portmoneSchema = Yup.object().shape({
  payeeIdDeposits: Yup.string(),
  payeeIdCertificates: Yup.string(),
  payeeIdCheckout: Yup.string(),
  payeeIdCheckoutTips: Yup.string(),
});

const PortmoneIntegrations = ({ ...rest }) => {
  const dispatch = useDispatch();

  const activePlace = useSelector(placeSelectors.getActivePlace());

  const connectPortmone = async data => {
    const result = await dispatch(
      placeThunks.updatePlaceConnections({
        id: activePlace.id,
        connections: {
          portmone: data
        },
      }),
    );
    if (result?.payload?.connections) {
      await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
    }
  };

  return (
    <Card {...rest}>
      <Formik
        initialValues={{
          payeeIdDeposits: activePlace.connections?.portmone?.payeeIdDeposits || '',
          payeeIdCertificates: activePlace.connections?.portmone?.payeeIdCertificates || '',
          payeeIdCheckout: activePlace.connections?.portmone?.payeeIdCheckout || '',
          payeeIdCheckoutTips: activePlace.connections?.portmone?.payeeIdCheckoutTips || '',
        }}
        onSubmit={connectPortmone}
        validationSchema={portmoneSchema}
      >
        <Box component={Form} display='flex' flexDirection='column' gap={2}>
          <Typography variant='h3'>
            {t('portmoneService')}
          </Typography>
          <PortmoneIntegrationRow place={activePlace} name='payeeIdDeposits' type='deposits' />
          <PortmoneIntegrationRow place={activePlace} name='payeeIdCertificates' type='certificates' />
          <PortmoneIntegrationRow place={activePlace} name='payeeIdCheckout' type='qr' />
          <PortmoneIntegrationRow place={activePlace} name='payeeIdCheckoutTips' type='tips' />
          <Box display='flex' justifyContent='center' mt={2} gap={2}>
            <Button type='submit'>{t('connect')}</Button>
          </Box>
        </Box>
      </Formik>
    </Card>
  );
};

export default PortmoneIntegrations;
