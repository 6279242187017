import React from 'react';
import * as Yup from 'yup';
import mova from 'mova';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { toastrActions } from 'state/ducks/toastr';
import PortmoneIntegrationRow from "./PortmoneIntegrationRow";

const t = mova.ns('components.Payments');
const tSettings = mova.ns('components.Settings');

const ukrcardSchema = Yup.object().shape({
  ukrcardIdDeposits: Yup.string(),
  ukrcardIdCertificates: Yup.string(),
  ukrcardIdCheckout: Yup.string(),
  ukrcardIdCheckoutTips: Yup.string(),
});

const PortmoneIntegrations = ({ ...rest }) => {
  const dispatch = useDispatch();

  const activePlace = useSelector(placeSelectors.getActivePlace());

  const connectPortmone = async data => {
    const result = await dispatch(
      placeThunks.updatePlaceConnections({
        id: activePlace.id,
        connections: {
          ukrcard: data
        },
      }),
    );
    if (result?.payload?.connections) {
      await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
    }
  };

  return (
    <Card {...rest}>
      <Formik
        initialValues={{
          ukrcardIdDeposits: activePlace.connections?.ukrcard?.ukrcardIdDeposits || '',
          ukrcardIdCertificates: activePlace.connections?.ukrcard?.ukrcardIdCertificates || '',
          ukrcardIdCheckout: activePlace.connections?.ukrcard?.ukrcardIdCheckout || '',
          ukrcardIdCheckoutTips: activePlace.connections?.ukrcard?.ukrcardIdCheckoutTips || '',
        }}
        onSubmit={connectPortmone}
        validationSchema={ukrcardSchema}
      >
        <Box component={Form} display='flex' flexDirection='column' gap={2}>
          <Typography variant='h3'>
            {t('ukrcardService')}
          </Typography>
          <PortmoneIntegrationRow placeholder={t('ukrcardKey')} place={activePlace} name='ukrcardIdDeposits' type='deposits' />
          <PortmoneIntegrationRow placeholder={t('ukrcardKey')} place={activePlace} name='ukrcardIdCertificates' type='certificates' />
          <PortmoneIntegrationRow placeholder={t('ukrcardKey')} place={activePlace} name='ukrcardIdCheckout' type='qr' />
          <PortmoneIntegrationRow placeholder={t('ukrcardKey')} place={activePlace} name='ukrcardIdCheckoutTips' type='tips' />
          <Box display='flex' justifyContent='center' mt={2} gap={2}>
            <Button type='submit'>{t('connect')}</Button>
          </Box>
        </Box>
      </Formik>
    </Card>
  );
};

export default PortmoneIntegrations;
