import React from 'react';
import * as Yup from 'yup';
import mova from 'mova';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { toastrActions } from 'state/ducks/toastr';
import PaymentProviderRow from "./PaymentProviderRow";

const t = mova.ns('components.Payments');
const tSettings = mova.ns('components.Settings');

const providersSchema = Yup.object().shape({
  deposits: Yup.string(),
  certificates: Yup.string(),
  checkout: Yup.string(),
  checkoutTips: Yup.string(),
});

const PaymentProviderSelection = ({ ...rest }) => {
  const dispatch = useDispatch();

  const activePlace = useSelector(placeSelectors.getActivePlace());

  const updateProviders = async data => {
    const result = await dispatch(
      placeThunks.updatePlaceConnections({
        id: activePlace.id,
        connections: {
          payments: data
        },
      }),
    );
    if (result?.payload?.connections) {
      await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
    }
  };

  return (
    <Card {...rest}>
      <Formik
        initialValues={{
          deposits: activePlace.connections?.payments?.deposits || '',
          certificates: activePlace.connections?.payments?.certificates || '',
          checkout: activePlace.connections?.payments?.checkout || '',
          checkoutTips: activePlace.connections?.payments?.checkoutTips || '',
        }}
        onSubmit={updateProviders}
        validationSchema={providersSchema}
      >
        <Box component={Form} display='flex' flexDirection='column' gap={2}>
          <Typography variant='h3'>
            {t('paymentProviders')}
          </Typography>
          <PaymentProviderRow name='deposits' type='deposits' />
          <PaymentProviderRow name='certificates' type='certificates' />
          <PaymentProviderRow name='checkout' type='qr' />
          <PaymentProviderRow name='checkoutTips' type='tips' />
          <Box display='flex' justifyContent='center' mt={2} gap={2}>
            <Button type='submit'>{t('save')}</Button>
          </Box>
        </Box>
      </Formik>
    </Card>
  );
};

export default PaymentProviderSelection;
