import React from 'react'
import styled from '@emotion/styled';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { get } from 'utils/lo/lo';

const PhoneWrapper = styled.div`
  position: relative;

  .react-tel-input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
  }

  .react-tel-input .form-control {
    width: 100%;
    height: 40px;
    padding: 8px 15px 8px 45px;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .react-tel-input .flag-dropdown {
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 8px;
  }

  .react-tel-input .country-list {
    top: 30px;
  }
`;

const ErrorWrapper = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  margin-left: 12px;
`;

const PhoneField = ({ field, form, disabled, ...props }) => {
  const { name } = field;
  const { setFieldValue, errors, touched } = form;

  const isTouched = get(touched, name);
  const phoneErrors = get(errors, name);
  return (
    <PhoneWrapper>
      <PhoneInput
        {...props}
        {...field}
        preferredCountries={['es', 'fi', 'ua']}
        country='ua'
        value={field.value}
        onChange={(value) => setFieldValue(name, `+${value}`)}
        enableSearch={true}
        disableDropdown={disabled}
        inputProps={{
          name,
          required: true,
          autoFocus: true,
          disabled: disabled
        }}
      />
      {isTouched && phoneErrors && (
        <ErrorWrapper>{phoneErrors}</ErrorWrapper>
      )}
    </PhoneWrapper>
  );
}

export default PhoneField
