const getOrganizations = () => state => state.superAdmin.organizations.data;
const getOrganizationsLoading = () => state => state.channel.list.isLoading;

const getSms = () => state => state.superAdmin.sms.data.rows;
const getSmsCount = () => state => state.superAdmin.sms.data.count;
const getSmsPage = () => state => state.superAdmin.sms.data.page;

const getLogs = () => state => state.superAdmin.logs.data.rows;
const getLogsCount = () => state => state.superAdmin.logs.data.count;
const getLogsPage = () => state => state.superAdmin.logs.data.page;

export default {
  getOrganizations,
  getOrganizationsLoading,
  getSms,
  getSmsCount,
  getSmsPage,
  getLogs,
  getLogsCount,
  getLogsPage,
};
