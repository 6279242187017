import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import mova from 'mova';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import Loader from '@components/Loader/Loader';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { toastrActions } from 'state/ducks/toastr';
import FormikSelect from '../../../../../form/FormikSelect';
import { integrationSelectors, integrationThunks } from 'state/ducks/integration';
import config from 'config';
import { integration } from 'state/ducks';

const t = mova.ns('components.Integrations');
const tSettings = mova.ns('components.Settings');

const portmoneSchema = Yup.object().shape({
  posterClientId: Yup.string(),
});

const application_id = 3826;

const PosterIntegrations = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const { account: posterAccount, code: posterCode } = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(false);
  const activePlace = useSelector(placeSelectors.getActivePlace());

  const terminals = useSelector(integrationSelectors.getPosterTerminals());
  const terminalsLoading = useSelector(integrationSelectors.getPosterTerminalsLoading());

  const connected = activePlace.integration?.type === 'jp' && activePlace.integration?.variables?.spotId;
  const connectedAuth = activePlace.integration?.type === 'jp';
  const synced = !!activePlace.integration?.lastSync;
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    const runIntegration = async () => {
    if(posterCode && posterAccount) {
      setIsLoading(true);
      await dispatch(placeThunks.addPosterConnection({
        placeId: activePlace.id,
        code: posterCode,
        account: posterAccount,
      }));
      setIsLoading(false);
      history.push(`${location.pathname}?${queryString.stringify({ path: 'integrations' })}`);
    }
    }
    runIntegration();
  }, [posterAccount, posterCode, activePlace.id, dispatch, setIsLoading, history]) // eslint-disable-line

  useEffect(() => {
    if(activePlace?.id && activePlace.integration?.id) {
      dispatch(integrationThunks.getPosterTerminals({
        placeId: activePlace.id,
        integrationId: activePlace.integration.id,
      }));
    }
  }, [activePlace.integration?.id, dispatch, activePlace?.id])

  const handleSyncPoster = useCallback(async () => {
      await dispatch(placeThunks.syncPosterTables({
        placeId: activePlace.id,
        integrationId: activePlace.integration.id,
      }));
      await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));

  }, [activePlace.integration?.id, dispatch, activePlace?.id])

    const resetPoster = async () => {
      setIsLoading(true);
      if(activePlace.integration?.id) {
        await dispatch(placeThunks.deletePosterConnection({
          placeId: activePlace.id,
          integrationId: activePlace.integration?.id,
        }));
        await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
      }
      setIsLoading(false);
    };

  return (
    <Card sx={{ mt: 4 }}>
      <Formik
        initialValues={{
          spotId: activePlace.integration?.variables?.spotId || null,
        }}
        onSubmit={async (data) => {
            setIsLoading(true);
            await dispatch(placeThunks.savePosterConnection({
              placeId: activePlace.id,
              integrationId: activePlace.integration.id,
              variables: { ...(activePlace.integration?.variables || {}), spotId: data.spotId }
            }));
            await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
            setIsLoading(false);
        }}
        validationSchema={portmoneSchema}
      >
        { formik => (
        <Form>
          <Typography variant='h3' sx={{ mb: 2 }}>
            {t('posterService')}
          </Typography>
          {activePlace.integration?.id && terminals.length > 0 && (
              <Box
                my={1}
                display='flex'
                alignItems={mobileView ? 'flex-start' : 'center'}
                justifyContent='space-between'
                flexDirection={mobileView ? 'column' : 'row'}
              >
                <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
                  {t('syrveTerminal')}
                </Typography>
                <Box flexGrow={5}>
                  <FormikSelect
                    fullWidth
                    name='spotId'
                    items={[
                      ...terminals.map(({ spot_id, spot_name }) => ({
                        id: spot_id,
                        name: spot_name,
                        value: spot_id,
                      })),
                    ]}
                    labelPath='name'
                  />
                </Box>
              </Box>
            )}          
          {connected && (
            <Box display='flex' alignItems='center' mt={1}>
              <Icon type='done' sx={{ mr: 1 }} />
              <Typography variant='h4' color='success.main'>
                {t('connected')}
              </Typography>
            </Box>
          )}
          {connected && (
            <Box display='flex' alignItems='center' mt={1}>
              <Icon type={synced ? 'done' : 'close'} sx={{ mr: 1 }} color={synced ? theme.palette.success.main : theme.palette.error.main} />
              <Typography variant='h4' color={synced ? 'success.main' : 'error.main'}>
                {synced ? t('synced') : t('notSynced')}
              </Typography>
            </Box>
          )}
          <Box display='flex' justifyContent='center' mt={2} gap={2}>
            {terminalsLoading && <Loader />}
            <Button type='submit' disabled={!connectedAuth}>{t('connect')}</Button>
            {terminalsLoading && <Loader />}
            <Button onClick={handleSyncPoster} disabled={!connected}>{t('syncPosterTables')}</Button>
            {isLoading ? <Loader /> : (
              <Button href={`https://joinposter.com/api/auth?application_id=${application_id}&redirect_uri=${`${config.restUiUrl}${location.pathname}?path=integrations`}&response_type=code`}>
                {connectedAuth ? 'Poster OAuth re-connect' : 'Poster OAuth connect'}
              </Button>
          )}
          </Box>
          <Box display='flex' justifyContent='center' mt={2} gap={2}>
          {connected && (
            <Button
            type='reset'
            color='primary'
            onClick={async () => {
              await resetPoster();
              formik.resetForm();
            }}
          >
            {t('reset')}
          </Button>
          )}           
          </Box>
          <Box display='flex' justifyContent='center' mt={2} gap={2}>
          </Box>
        </Form>
        )}
      </Formik>
    </Card>
  );
};

export default PosterIntegrations;
