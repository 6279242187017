import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';
import localStorage from 'utils/localStorage';

const getLang = () => localStorage.get(localStorage.Keys.LANG) || 'ua';

const fetchCountries = createAsyncThunk('dictionary/fetchCountries', async () => {
  return api.get(`/dictionaries/countries`);
});

const addEditCountry = createAsyncThunk('dictionary/addEditCountry', async (country) => {
  return api.put(`/dictionaries/countries`, country);
});

const fetchCities = createAsyncThunk('dictionary/fetchCities', async () => {
  return api.get(`/dictionaries/cities?full=true`);
});

const addEditCity = createAsyncThunk('dictionary/addEditCity', async (city) => {
  return api.put(`/dictionaries/cities`, city);
});

const fetchTags = createAsyncThunk('dictionary/fetchTags', async () => {
  return api.get(`/dictionaries/categories?full=true`);
});

const addEditTag = createAsyncThunk('dictionary/addEditTag', async (tag) => {
  return api.put(`/dictionaries/categories`, tag);
});

const fetchCusines = createAsyncThunk('dictionary/fetchCusines', async () => {
  return api.get(`/dictionaries/categories?type=cusine&lang=${getLang()}`);
});

const fetchMoods = createAsyncThunk('dictionary/fetchMoods', async () => {
  return api.get(`/dictionaries/categories?type=mood&lang=${getLang()}`);
});

const fetchPayments = createAsyncThunk('dictionary/fetchPayments', async () => {
  return api.get(`/dictionaries/categories?type=payment&lang=${getLang()}`);
});

export default {
  fetchCountries,
  addEditCountry,
  fetchCities,
  fetchCusines,
  fetchMoods,
  fetchPayments,
  getLang,
  addEditCity,
  fetchTags,
  addEditTag,
};
