import React, { memo } from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import mova from 'mova';
import { MenuItem, useMediaQuery } from "@mui/material";
import FormikSelect from "../../../../../form/FormikSelect";

const t = mova.ns('components.Payments');

const PROVIDER_PORTMONE = "portmone";
const PROVIDER_UKRCARD = "ukrcard";

const PaymentProviderRow = ({ name, type }) => {
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      display='flex'
      alignItems={mobileView ? 'flex-start' : 'center'}
      justifyContent='space-between'
      flexDirection={mobileView ? 'column' : 'row'}
    >
      <Box display='flex' alignItems='center' gap={1}>
        <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
          {t(`types.${type}`)}
        </Typography>
      </Box>
      <FormikSelect wrapperProps={{ flexGrow: 3 }} name={name} fullWidth placeholder={t('selectProvider')}>
        <MenuItem key={PROVIDER_PORTMONE} value={PROVIDER_PORTMONE}>Portmone</MenuItem>
        <MenuItem key={PROVIDER_UKRCARD} value={PROVIDER_UKRCARD}>Ukrcard</MenuItem>
      </FormikSelect>
    </Box>
  );
};

export default memo(PaymentProviderRow);
